<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h4>Legacy data for: {{ instruments[0].name }}</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4">
        <label>Change data set</label>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4">
        <select v-model="dataSet">
          <option
            v-for="(exchange, index) in exchanges"
            v-bind:key="index"
            v-bind:value="exchange.name">
            {{ exchange.name }}
          </option>
        </select>
      </div>
      <div class="cell small-12 medium-4">
        <button v-on:click="changeData" class="button">Change Data Set</button>
      </div>
    </div>
    <hr />
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <label>Select data type</label>
        <select v-model="dataType">
          <option value="1">Futures</option>
          <option value="2">Options</option>
          <option value="3">Combined</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Include Spread?</label>
        <div class="switch large">
          <input
            v-model="includeSpread"
            class="switch-input"
            id="inc-spr"
            type="checkbox"
            name="inc-spr-switch">
          <label class="switch-paddle" for="inc-spr">
            <span class="show-for-sr">Include Spread?</span>
            <span class="switch-active" aria-hidden="true">Y</span>
            <span class="switch-inactive" aria-hidden="true">N</span>
          </label>
        </div>
      </div>
      <div class="cell small-12 medium-2">
        <label>Position?</label>
        <select v-model="position">
          <option value="1">Non</option>
          <option value="2">Com</option>
          <option value="3">Small</option>
        </select>
      </div>
      <div class="cell small-12 medium-2">
        <label>Date Range Start</label>
        <datepicker id="crs" v-model="dateRangeStart"></datepicker>
      </div>
      <div class="cell small-12 medium-2">
        <label>Date Range End</label>
        <datepicker id="cre" v-model="dateRangeEnd"></datepicker>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table class="wide-table">
          <thead>
            <tr>
              <th colspan="2"></th>
              <th colspan="5">Long OI</th>
              <th colspan="5">Short OI</th>
              <th colspan="7">NON</th>
              <th colspan="7">COM</th>
              <th colspan="7">SMALL</th>
            </tr>
            <tr>
              <th>Date</th>
              <th>Open Interest</th>
              <th class="lightblue">Non</th>
              <th class="lightblue">Spread</th>
              <th class="lightblue">T Non</th>
              <th class="brown">Com</th>
              <th class="grey">Small</th>
              <th class="lightblue">Non</th>
              <th class="lightblue">Spread</th>
              <th class="lightblue">T Non</th>
              <th class="brown">Com</th>
              <th class="grey">Small</th>
              <th class="lightblue">Long</th>
              <th class="lightblue">Short</th>
              <th class="lightblue">Long CH</th>
              <th class="lightblue">Short CH</th>
              <th class="lightblue">Total</th>
              <th class="lightblue">Index</th>
              <th class="lightblue">Move</th>
              <th class="brown">Long</th>
              <th class="brown">Short</th>
              <th class="brown">Long CH</th>
              <th class="brown">Short CH</th>
              <th class="brown">Total</th>
              <th class="brown">Index</th>
              <th class="brown">Move</th>
              <th class="grey">Long</th>
              <th class="grey">Short</th>
              <th class="grey">Long CH</th>
              <th class="grey">Short CH</th>
              <th class="grey">Total</th>
              <th class="grey">Index</th>
              <th class="grey">Move</th>
              <th class="red">Position L</th>
              <th class="red">Position S</th>
              <th class="red">Option L</th>
              <th class="red">Option S</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(instrument, index) in filteredInstruments" v-bind:key="index">
              <td>{{ instrument.date | moment("Do MMM YYYY") }}</td>
              <td>{{ instrument.e | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.at | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.av | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.longTnon | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ instrument.aw | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ instrument.ba | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.au | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.av | numeral('0,0.[00]') }}</td>
              <td class="lightblue">{{ instrument.shortTnon | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ instrument.ax | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ instrument.bb | numeral('0,0.[00]') }}</td>
              <td class="lightblue">
                <span v-if="includeSpread === true">
                  {{ (parseInt(instrument.h, 10) + parseInt(instrument.f, 10))
                  | numeral('0,0.[00]') }}
                </span>
                <span v-else>{{ instrument.f | numeral('0,0.[00]') }}</span>
              </td>
              <td class="lightblue">
                <span v-if="includeSpread === true">
                  {{ (parseInt(instrument.h, 10) + parseInt(instrument.g, 10))
                  | numeral('0,0.[00]') }}
                </span>
                <span v-else>{{ instrument.g | numeral('0,0.[00]') }}</span>
              </td>
              <td class="lightblue">
                <span v-if="includeSpread === true">
                  {{ (parseInt(instrument.al, 10) + parseInt(instrument.aj, 10))
                  | numeral('0,0.[00]') }}
                </span>
                <span v-else>{{ instrument.aj | numeral('0,0.[00]') }}</span>
              </td>
              <td class="lightblue">
                <span v-if="includeSpread === true">
                  {{ (parseInt(instrument.al, 10) + parseInt(instrument.ak, 10))
                  | numeral('0,0.[00]') }}
                </span>
                <span v-else>{{ instrument.ak | numeral('0,0.[00]') }}</span>
              </td>
              <td class="lightblue">
                  {{ (parseInt(instrument.f, 10) - parseInt(instrument.g, 10))
                  | numeral('0,0.[00]') }}
              </td>
              <td class="lightblue"></td>
              <td class="lightblue"></td>
              <td class="brown">{{ instrument.i | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ instrument.j | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ instrument.am | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ instrument.an | numeral('0,0.[00]') }}</td>
              <td class="brown">{{ parseInt(instrument.i) - parseInt(instrument.j) }}</td>
              <td class="brown"></td>
              <td class="brown"></td>
              <td class="grey">{{ instrument.m | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ instrument.n | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ instrument.aq | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ instrument.ar | numeral('0,0.[00]') }}</td>
              <td class="grey">{{ parseInt(instrument.m) - parseInt(instrument.n) }}</td>
              <td class="grey"></td>
              <td class="grey"></td>
              <td class="red"></td>
              <td class="red"></td>
              <td class="red"></td>
              <td class="red"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from '../axios';

export default {
  name: 'ViewLegacy',
  data() {
    return {
      exchanges: [],
      dataType: 1,
      dataSet: 'EURO FX - CHICAGO MERCANTILE EXCHANGE',
      includeSpread: false,
      instruments: [],
      filteredInstruments: [],
      position: 1,
      dateRangeStart: new Date(2021, 0, 1),
      dateRangeEnd: new Date(),
    };
  },
  components: {
    Datepicker,
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  watch: {
    dateRangeStart(value) {
      const newDate = new Date(value);
      this.filteredInstruments = this.instruments;
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > newDate));
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
    },
    dateRangeEnd(value) {
      const newDate = new Date(value);
      this.filteredInstruments = this.instruments;
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < newDate));
      // eslint-disable-next-line
      this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
    },
    dataType(value) {
      this.instruments = [];
      axios.get(`/legacies/getInstruments/${value}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
        });
    },
  },
  methods: {
    getExchanges() {
      axios.get(`/legacies/getExchanges.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.exchanges = response.data.exchanges;
        });
    },
    getInstruments() {
      axios.get(`/legacies/getInstruments/1.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
        });
    },
    changeData() {
      this.instruments = [];
      axios.get(`/legacies/getInstruments/${this.dataType}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&exchange=${this.dataSet}`)
        .then((response) => {
          this.instruments = response.data.instruments;
          this.filteredInstruments = response.data.instruments;
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInstruments = this.filteredInstruments.filter((instrument) => (new Date(instrument.date) < this.dateRangeEnd));
        });
    },
  },
  mounted() {
    this.getExchanges();
    this.getInstruments();
  },
};
</script>
